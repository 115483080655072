























import {Component, Vue} from "vue-property-decorator";
import {TrelloAPI} from "@/api/trello/TrelloAPI";
import {Status} from "@/api/indexeddb/types";

@Component({ name: "Step2" })
export default class Step2 extends Vue {
    protected trelloAPI: TrelloAPI;
    private list = []

    constructor() {
        super();
        this.trelloAPI = new TrelloAPI();
    }

    get lists() {

        this.$store.state.lists.then( (list: any) => {
            this.list = list;
            if(list.length == 0) {
                this.trelloAPI.getListsOnBoard(this.$store.getters.getSelectedBoard).then((response: any) => {
                    response.forEach( (list: any) => {
                        this.$store.commit('addLists', {
                            id: list.id,
                            name: list.name,
                            idBoard: list.idBoard,
                            status: Status.NotAssigned
                        });
                    });
                })
            }
        });

        return this.list;
    }

    get inProgressListIds() {
        return this.$store.state.inProgressListIds;
    }
    set inProgressListIds( values ) {
        this.$store.commit('updateInProgressListIds',{inProgressListIds: values});
    }
}
